import './App.css';

import Homepage from './pages/Home';
import { useState } from 'react';
import ArtistInfo from './pages/Match';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Quiz from './pages/Quiz';  // Updated to match the folder name case

export type existingMatch ={
  request_id:string,
  "search_image": string,
  "art_details": any,
  "confidence": number,
  "artist": {
      "artist_photo": string,
      "name": string,
      "bio": string,
      "website"?: string,
      "twitter"?: string,
      "instagram"?: string,
      "tik_tok"?: string,
      "behance"?: string,
      "dribbble"?: string
  }
} 
export type artistMatchType = existingMatch | undefined

function App() {
  const [artistMatch,setArtistMatch] = useState<artistMatchType>()

  return(
    <>
      <BrowserRouter>
        <Routes>

          <Route
          path=''
          element={
            <Homepage setArtistMatch={setArtistMatch} />
          }
          />

          <Route
           path='/match'
           element={
            <ArtistInfo artistMatch={artistMatch} setArtistMatch={setArtistMatch} />
          }
          />

          <Route
            path='/african-art-quiz'
            element={<Quiz />}
          />
        </Routes>

      </BrowserRouter>
    </>
  )
}

export default App;
