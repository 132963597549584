import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

const Quiz: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://tally.so/widgets/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Flex flexDirection="column" height="100vh">
            <NavBar />
            <Flex direction="column" flex="1" overflow="hidden">
                {/* <Text fontSize={{ base: '24px', lg: '42px' }}
                    fontWeight={700} color={'#1C1C1C'} textAlign={'center'} mb={4}>
                    How well do you know African art?
                </Text> */}
                <Box flex="1" position="relative">
                    <iframe
                        data-tally-src="https://tally.so/r/wo7O0X?transparentBackground=1"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        marginHeight={0}
                        marginWidth={0}
                        title="How well do you know African art?"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    />
                </Box>
            </Flex>
            {/* <Footer /> */}
        </Flex>
    );
};

export default Quiz;